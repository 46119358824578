var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import * as classNames from "classnames";
var update = require("immutability-helper");
var FAQ = /** @class */ (function (_super) {
    __extends(FAQ, _super);
    function FAQ(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            active: {},
        };
        return _this;
    }
    FAQ.prototype.render = function () {
        var items = [
            {
                question: "Qui a le droit de venir au forum Arts et Métiers ?",
                answer: "Ce forum est ouvert à tous les étudiants et est totalement gratuit. Pas besoin d’inscriptions pour participer au Forum Arts et Métiers ! Seules les inscriptions pour participer à un Business lunch sont closes pour 2024",
            },
            {
                question: "Comment venir au Forum ?",
                answer: "Des bus sont prévus (et payés par la Fondation Arts et Métiers) aux départs de chaque campus et pour chaque élève de 2ème et 3ème années en PGE. Pour les étudiants venant par leurs propres moyens, le parc floral est accessible avec le métro 1, le RER A ou le T3a en direction du Château de Vincennes.",
            },
            {
                question: "Peut-on manger au forum ?",
                answer: "Vous aurez accès à des sandwichs afin de manger durant la pause de midi. Les prix seront très avantageux, car le Forum prendra à sa charge 70 % du prix de vente normal.",
            },
            {
                question: "Les conférences seront-elles filmées et retranscrites ?",
                answer: "Cette année, les conférences ne seront pas filmées, pour pouvoir y assister, il faudra être présent au forum.",
            },
            {
                question: "Comment connaître les entreprises présentes lors du forum ?",
                answer: "Les entreprises présentes lors du forum sont indiquées dans l'onglet Exposants avec diverses informations les concernant, nous vous invitons donc à vous y rendre pour les découvrir.",
            },
            {
                question: "Quelles opportunités professionnelles offre le forum ?",
                answer: "Le forum permet de trouver plusieurs types de contrats (stages, CDD, CDI, VIE,..) dans divers types d'entreprises (Start-up, Entreprise de conseil, Multi-national, PME,..) et dans divers domaines.",
            },
        ];
        var self = this;
        return (React.createElement("section", { id: "faq", className: "bg-white" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center mb-5" },
                    React.createElement("div", { className: "col-lg-6 text-center mb-3 mb-lg-5" },
                        React.createElement("h3", { className: "font-alt font-w-700 letter-spacing-2 m-0 text-uppercase title-small" }, "Foire aux questions"),
                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-1 mt-2 text-gray-800 text-small text-uppercase" }, "Questions fr\u00E9quentes, infos pratiques, etc."),
                        React.createElement("span", { className: "bg-blue d-block mt-4 mx-auto sep-line-medium-thick-long" }))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-8 offset-lg-2 mt-5 mt-lg-0" },
                        React.createElement("div", { id: "accordion-faq", className: "accordion", role: "tablist" }, items.map(function (item, i) {
                            return (React.createElement("div", { className: "card", key: i },
                                React.createElement("div", { className: "card-header", role: "tab", id: "heading1" },
                                    React.createElement("a", { onClick: function () {
                                            self.setState(function (state) {
                                                var _a;
                                                return update(state, {
                                                    active: {
                                                        $merge: (_a = {},
                                                            _a[i] = !state.active[i],
                                                            _a),
                                                    },
                                                });
                                            });
                                        }, "data-toggle": "collapse", "aria-expanded": "false", "aria-controls": "collapse" + i, style: { cursor: "pointer" }, className: classNames("font-alt font-w-700 letter-spacing-1 text-extra-small text-gray-900 text-uppercase", {
                                            collapsed: !self.state.active[i],
                                        }) }, item.question)),
                                React.createElement("div", { id: "collapse1", className: classNames("collapse", {
                                        show: self.state.active[i],
                                    }), role: "tabpanel", "aria-labelledby": "heading1", "data-parent": "#accordion-faq" },
                                    React.createElement("div", { className: "card-body" },
                                        React.createElement("p", { className: "m-0 text-small" }, item.answer)))));
                        })))))));
    };
    return FAQ;
}(React.Component));
export { FAQ };
