var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { withApi } from "../../decorators/api";
var Figures = /** @class */ (function (_super) {
    __extends(Figures, _super);
    function Figures() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Figures.prototype.componentWillMount = function () {
        this.props.loadEntreprises();
    };
    Figures.prototype.render = function () {
        var numExposants = Object.keys(this.props.exposants).length;
        return (React.createElement("div", { id: "milestones", className: "bg-white pb-5" },
            React.createElement("div", { className: "container py-5" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-3 border-gray-200 border-md-right text-center" },
                        React.createElement("span", { className: "timer d-block font-alt font-w-700 letter-spacing-1 title-extra-large-2", "data-from": "10", "data-to": "27", "data-speed": "1500" }, "4"),
                        React.createElement("span", { className: "bg-blue d-block mt-2 mx-auto sep-line-thick" }),
                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-2 mt-3 text-gray-800 text-large text-uppercase" }, "Conf\u00E9rences")),
                    React.createElement("div", { className: "col-md-3 border-gray-200 border-md-right mt-5 mt-md-0 text-center" },
                        React.createElement("span", { className: "timer d-block font-alt font-w-700 letter-spacing-1 title-extra-large-2" }, "3 500"),
                        React.createElement("span", { className: "bg-blue d-block mt-2 mx-auto sep-line-thick" }),
                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-2 mt-3 text-gray-800 text-large text-uppercase" }, "\u00C9tudiants")),
                    React.createElement("div", { className: "col-md-3 border-gray-200 border-md-right mt-5 mt-md-0 text-center" },
                        React.createElement("span", { className: "timer d-block font-alt font-w-700 letter-spacing-1 title-extra-large-2", "data-from": "0", "data-to": "17", "data-speed": "1500" }, numExposants),
                        React.createElement("span", { className: "bg-blue d-block mt-2 mx-auto sep-line-thick" }),
                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-2 mt-3 text-gray-800 text-large text-uppercase" }, "Exposants")),
                    React.createElement("div", { className: "col-md-3 mt-5 mt-md-0 text-center" },
                        React.createElement("span", { className: "timer d-block font-alt font-w-700 letter-spacing-1 title-extra-large-2", "data-from": "0", "data-to": "53", "data-speed": "1500" }, "5"),
                        React.createElement("span", { className: "bg-blue d-block mt-2 mx-auto sep-line-thick" }),
                        React.createElement("span", { className: "d-block font-alt font-w-600 letter-spacing-2 mt-3 text-gray-800 text-large text-uppercase" }, "Partenaires forum"))))));
    };
    Figures = __decorate([
        withApi(function (state) { return ({
            exposants: state.network.exposants,
        }); })
    ], Figures);
    return Figures;
}(React.Component));
export { Figures };
